'use client';
import RatingStars from '@/components/shared/rating-stars/rating-stars.component';
import Typography from '@/components/shared/typography/typography.component';
import classes from './styles.module.scss';

export default function TestimonialItem({
    verbatim,
    name,
    rating,
}: {
    verbatim: string;
    name: string;
    rating: number;
}) {
    return (
        <div
            className={`${classes.testimonialItem} ar:[direction:rtl] en:[direction:ltr]`}
        >
            <div className={classes.reviewContainer}>
                <div className={classes.review}>
                    <Typography variant={'body1Medium'}>{verbatim}</Typography>
                </div>
            </div>
            <div className={classes.attributionContainer}>
                <div className={classes.nameAndDate}>
                    <Typography
                        variant={'body2'}
                        className={classes.name}
                    >{`— ${name}`}</Typography>
                </div>
                <RatingStars rating={rating} />
            </div>
        </div>
    );
}
