'use client';
import Typography from '@/components/shared/typography/typography.component';
import { LineDesktop } from '../../../../public/Images/old-images';
import { useTranslation, useWindowDimensions } from '@/hook';
import Image from 'next/image';
import styles from './styles.module.scss';
import { WebsiteHeroBg } from '../../../../public/Images';
import dynamic from 'next/dynamic';
import { DimensionsTargets } from '@/hook/use-window-width.hook';

const SellCarAtomicForm = dynamic(
    () =>
        import('../sell-car-atomic-form/sell-car-atomic-form.component').then(
            mod => mod.default
        ),
    {
        ssr: false,
    }
);

const AcquisitionDesktopHeader = () => {
    const { t, IS_RTL } = useTranslation();
    const { IS_DESKTOP } = useWindowDimensions(DimensionsTargets.DESKTOP);
    return (
        <section className={styles.header_container}>
            <Image
                className={styles.header_container_img}
                src={WebsiteHeroBg.src}
                width={1512}
                height={648}
                quality={85}
                alt={t('sellCarSylndr')}
                loading={'eager'}
            />
            <div className={styles.header_text_container}>
                <div>
                    <Typography
                        className={styles.header_text}
                        style={{
                            lineHeight: !IS_RTL ? 1 : 1.3,
                            letterSpacing: !IS_RTL ? -3 : 0,
                        }}
                    >
                        {t('bannerHeader1')}
                    </Typography>{' '}
                    <Typography
                        className={styles.header_text}
                        style={{
                            lineHeight: !IS_RTL ? 1 : 1.3,
                            letterSpacing: !IS_RTL ? -3 : 0,
                        }}
                    >
                        {t('bannerHeader2')}
                    </Typography>
                </div>
                <Image
                    src={LineDesktop.src}
                    width={LineDesktop.width}
                    height={LineDesktop.height}
                    style={{
                        width: !IS_RTL ? '25vw' : '22vw',
                        marginBottom: '2vw',
                    }}
                    alt="sell your car"
                />
            </div>
            {IS_DESKTOP && <SellCarAtomicForm />}
        </section>
    );
};

export default AcquisitionDesktopHeader;
