import { atom } from 'jotai/index';
import {
    Make,
    Model,
    Year,
    kilometers,
    Transmission,
    InspectionType,
    LocationStep,
    IContactInfo,
    sylndrHubStep,
} from '@/types/acquistion/atomic-sell-form.types';

export const inspectionTypeAtom = atom<InspectionType>(null);
export const makeAtom = atom<Make | null>(null);
export const modelAtom = atom<Model | null>(null);
export const yearAtom = atom<Year | null>(null);
export const kilometerAtom = atom<kilometers | null>(null);
export const transmitionAtom = atom<Transmission | null>(null);
export const locationAtom = atom<LocationStep | null>(null);
export const contactInfoAtom = atom<IContactInfo | null>(null);
export const sylndrHubsAtom = atom<sylndrHubStep | null>(null);
