export { default as Home } from './homeIcon.svg';
export { default as Tag } from './tagIcon.svg';
export { default as Free } from './freeIcon.svg';
export { default as WhySellImage } from './whySellImage.png';
export { default as Vector } from './vector.svg';
export { default as Line } from './line.svg';
export { default as work3 } from './work3.svg';
export { default as work4 } from './work4.svg';
export { default as LineDesktop } from './lineDesktop.svg';
export { default as GreenCheckMark } from './greenCheckMark.svg';
