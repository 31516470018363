'use client';
import { Star } from '../../../../public/Images';
import Image from 'next/image';

const RatingStars = ({ rating }: { rating: number }) => {
    return (
        <div className={'flex items-center gap-1 justify-start'}>
            {Array.from(Array(rating).keys()).map((_, i) => (
                <Image
                    loading={'lazy'}
                    priority={false}
                    src={Star.src}
                    width={Star.width}
                    height={Star.height}
                    key={i + 'SS'}
                    alt="rating star"
                />
            ))}
        </div>
    );
};

export default RatingStars;
