'use client';
import VidOverlay from '@/components/shared/vidOverlay/vid-overlay.component';
import { t } from 'i18next';
import Slider, { Settings } from 'react-slick';
import { useEffect, useRef, useState } from 'react';
import { useTranslation, useWindowDimensions } from '@/hook';
import { SourceScreen } from '@/types/source-screens.types';
import styles from './styles.module.scss';
import { useAnalytics } from '@/integrations';

const TestimonialVids = ({ backgroundColor, sourceScreen }) => {
    const { IS_DESKTOP } = useWindowDimensions();
    const { IS_RTL } = useTranslation();
    const sliderRef = useRef<Slider>(null);
    const { trackEvent } = useAnalytics();
    const gtmEvent = () => {
        trackEvent('Action: Watch_Testimonials', {
            SourceScreen,
        });
    };
    const testimonialData = [
        {
            shortUrl:
                'https://sylndr-website.s3.us-east-2.amazonaws.com/vid-min-quality/renameShort.mp4',
            thumbnailUrl:
                'https://sylndr-website.s3.us-east-2.amazonaws.com/vid/thumbnail+4.png',
            longUrl:
                'https://sylndr-website.s3.us-east-2.amazonaws.com/vid-min-quality/trust.mp4',
            category: t('testamonials.trust'),
            rating: 5,
            userName: 'Akram Tayseer',
        },
        {
            shortUrl:
                'https://sylndr-website.s3.us-east-2.amazonaws.com/vid-min-quality/7DaysReturnShort.mp4',
            thumbnailUrl:
                'https://sylndr-website.s3.us-east-2.amazonaws.com/vid/thumbnail+6.png',
            longUrl:
                'https://sylndr-website.s3.us-east-2.amazonaws.com/vid-min-quality/7DaysReturn.mp4',
            category: t('testamonials.return'),
            rating: 4,
            userName: 'Mohamed Mostafa',
        },
        {
            shortUrl:
                'https://sylndr-website.s3.us-east-2.amazonaws.com/vid-min-quality/financeShort.mp4',
            thumbnailUrl:
                'https://sylndr-website.s3.us-east-2.amazonaws.com/vid/moatz+2+.png',
            longUrl:
                'https://sylndr-website.s3.us-east-2.amazonaws.com/vid-min-quality/finance.mp4',
            category: t('testamonials.finance'),
            rating: 4,
            userName: 'Moataz Hassan',
        },
        {
            shortUrl:
                'https://sylndr-website.s3.us-east-2.amazonaws.com/vid-min-quality/homeVisitShort.mp4',
            thumbnailUrl:
                'https://sylndr-website.s3.us-east-2.amazonaws.com/vid/thumbnail+7.png',
            longUrl:
                'https://sylndr-website.s3.us-east-2.amazonaws.com/vid-min-quality/homeVisit.mp4',
            category: t('testamonials.homeVisit'),
            rating: 5,
            userName: 'Dr. Walaa Mounir',
        },
    ];

    useEffect(() => {
        IS_RTL
            ? sliderRef.current.slickGoTo(1)
            : sliderRef.current.slickGoTo(0);
    }, []);

    const sliderSettings: Settings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        useTransform: false,
        autoplay: false,
        autoplaySpeed: 10000,
        arrows: false,
        rtl: IS_RTL,
        rows: 1,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    centerMode: true,
                    variableWidth: false,
                    slidesToShow:
                        sourceScreen === SourceScreen.CAR_LISTING ? 2 : 3,
                    slidesToScroll: 1,
                    autoplay: false,
                    useTransform: false,
                    autoplaySpeed: 10000,
                    arrows: false,
                    rtl: IS_RTL,
                    rows: 1,
                },
            },
            {
                breakpoint: 1000,
                settings: {
                    centerMode: true,
                    variableWidth: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    autoplay: false,
                    useTransform: false,
                    autoplaySpeed: 10000,
                    rtl: IS_RTL,
                    arrows: false,
                    rows: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    centerMode: true,
                    variableWidth: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: false,
                    autoplaySpeed: 10000,
                    arrows: false,
                    useTransform: false,
                    rows: 1,
                },
            },
        ],
    };

    const [playing, setPlaying] = useState(
        testimonialData.map(el => (el ? false : false))
    );

    const setPlayingVid = index => {
        const newPlayingArr = playing.map((playing, i) =>
            index === i ? !playing : false
        );
        setPlaying(newPlayingArr);
    };

    const getVidsArr = (): any[] => {
        if (IS_RTL && IS_DESKTOP) return testimonialData.reverse();
        else return testimonialData;
    };

    return (
        <div
            className={styles.slidern}
            style={{ backgroundColor: backgroundColor || 'transparent' }}
        >
            <Slider {...sliderSettings} ref={sliderRef}>
                {getVidsArr().map((item, i) => (
                    <VidOverlay
                        shortUrl={item.shortUrl}
                        thumbnailUrl={item.thumbnailUrl}
                        longUrl={item.longUrl}
                        category={item.category}
                        rating={item.rating}
                        userName={item.userName}
                        gtmEvent={gtmEvent}
                        key={item.longUrl}
                        playing={playing[i]}
                        setPlaying={setPlayingVid}
                        index={i}
                    />
                ))}
            </Slider>
        </div>
    );
};

export default TestimonialVids;
