'use client';
import classes from './styles.module.scss';
import {
    Free,
    Home,
    Tag,
    WhySellImage,
} from '../../../../public/Images/old-images';
import { useTranslation } from '@/hook';
import { useEffect } from 'react';
import Slider from 'react-slick';
import Typography from '@/components/shared/typography/typography.component';
import Image from 'next/image';

export default function WhySellYourCarSection() {
    const animSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: 'work-slider',
        arrows: false,
        responsive: [
            {
                breakpoint: 768,
            },
            {
                breakpoint: 10000, // a unrealistically big number to cover up greatest screen resolution
                settings: 'unslick',
            },
        ],
    };
    const { t } = useTranslation();

    function reveal() {
        const reveals = document.getElementsByClassName(classes.reveal);

        for (const revealItem of reveals) {
            const windowHeight = window.innerHeight;
            const elementTop = revealItem.getBoundingClientRect().top;
            const elementVisible = 250;

            if (elementTop < windowHeight - elementVisible) {
                revealItem.classList.add(classes.reveal_active);
            } else {
                revealItem.classList.remove(classes.reveal_active);
            }
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', reveal);

        // clean up the component
        return () => {
            window.removeEventListener('scroll', reveal);
        };
    }, []);

    return (
        <div className={`${classes.sold} ${classes.whiteBg}`}>
            <Typography variant="h6Bold">
                {t('Why Sell')}
                <span style={{ color: '#3b9c53' }}>{t('Sylndr')}</span> {t('?')}
            </Typography>
            <Typography
                variant={'body2'}
                className={classes.subtitle}
                style={{ LineHeight: '300%' }}
            >
                {t('Reasons to Sell')}
            </Typography>

            <div>
                <Image
                    src={WhySellImage.src}
                    width={WhySellImage.width}
                    height={WhySellImage.height}
                    alt="animation"
                />
            </div>

            <div className={classes.animationContainer}>
                <div className={classes.whySellContainer}>
                    <div
                        className={`${classes.animCard}
                    ${classes.reveal}`}
                    >
                        <Image
                            src={Home.src}
                            width={Home.width}
                            height={Home.height}
                            alt="animation"
                        />
                        <Typography
                            variant="subtitle2Bold"
                            className={classes.anim_title}
                        >
                            {t('Free Experience')}
                        </Typography>
                        <Typography
                            variant={'body2'}
                            className={classes.animTextDesktop}
                        >
                            {t('Free Experience Details')}
                        </Typography>
                    </div>

                    <div
                        className={`${classes.animCard}
                  ${classes.reveal}
                  ${classes.middle_animCard}`}
                    >
                        <Image
                            src={Tag.src}
                            width={Tag.width}
                            height={Tag.height}
                            alt="animation"
                        />
                        <Typography
                            variant="subtitle2Bold"
                            className={classes.anim_title}
                        >
                            {t('Instant Offer')}
                        </Typography>
                        <Typography
                            variant="body2"
                            className={classes.animTextDesktop}
                        >
                            {t('Instant Offer Details')}
                        </Typography>
                    </div>

                    <div
                        className={`
                    ${classes.animCard}
                    ${classes.reveal} ${classes.margin100}`}
                    >
                        <Image
                            src={Free.src}
                            width={Free.width}
                            height={Free.height}
                            alt="animation"
                        />
                        <Typography
                            variant="subtitle2Bold"
                            className={classes.anim_title}
                        >
                            {t('No Costs')}
                        </Typography>
                        <Typography
                            variant="body2"
                            className={classes.animTextDesktop}
                        >
                            {t('No Costs Details')}
                        </Typography>
                    </div>
                </div>
            </div>
            {/* Mobile View  */}
            <div
                className={`${classes.animationContainer}
              ${classes.slideAnim} ${classes.mobileContainer}
              `}
            >
                <div style={{ flex: '0 0 auto', width: '100%' }}>
                    {/* @ts-ignore */}
                    <Slider {...animSettings}>
                        <div
                            className={classes.animCard}
                            style={{ width: '75%' }}
                        >
                            <Image
                                src={Home.src}
                                width={Home.width}
                                height={Home.height}
                                alt="animation"
                            />
                            <Typography
                                variant="subtitle2Bold"
                                style={{
                                    marginTop: '2%',
                                    textAlign: 'center',
                                }}
                            >
                                {t('Free Experience')}
                            </Typography>
                            <Typography
                                variant="body2"
                                className={classes.animText}
                            >
                                {t('Free Experience Details')}
                            </Typography>
                        </div>
                        <div
                            className={classes.animCard}
                            style={{ width: '75%' }}
                        >
                            <Image
                                src={Tag.src}
                                width={Tag.width}
                                height={Tag.height}
                                alt="animation"
                            />
                            <Typography
                                variant="subtitle2Bold"
                                style={{
                                    marginTop: '2%',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {t('Instant Offer')}
                            </Typography>
                            <Typography
                                variant="body2"
                                className={classes.animText}
                            >
                                {t('Instant Offer Details')}
                            </Typography>
                        </div>
                        <div
                            className={classes.animCard}
                            style={{ width: '75%' }}
                        >
                            <Image
                                src={Free.src}
                                width={Free.width}
                                height={Free.height}
                                alt="animation"
                            />
                            <Typography
                                variant="subtitle2Bold"
                                style={{
                                    marginTop: '2%',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {t('No Costs')}
                            </Typography>
                            <Typography
                                variant="body2"
                                className={classes.animText}
                            >
                                {t('No Costs Details')}
                            </Typography>
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
    );
}
