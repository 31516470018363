'use client';
import { useEffect, useState } from 'react';
import classes from './styles.module.scss';
import Link from '@/components/WrappedLink';
import { ArrowDown } from '../../../../public/Images';
import { useTranslation } from '@/hook';
import Typography from '@/components/shared/typography/typography.component';
import { useGetFAQsQuery } from '@/hook/faqs-api-slice';
import Image from 'next/image';

const Accordian = ({
    header,
    items,
    open,
    expandQuestion,
    lastQuestion,
}: {
    header: string;
    items: JSX.Element[];
    open: boolean;
    expandQuestion: (header: string) => void;
    lastQuestion: boolean;
}) => {
    return (
        <div style={{ width: '100%' }}>
            <button
                onClick={() => expandQuestion(header)}
                className={classes.accordion_header_button}
                style={{ borderBottom: lastQuestion && !open ? 'none' : '' }}
            >
                <Typography
                    style={{
                        fontWeight: open ? 'bold' : '',
                        fontSize: '14px',
                        width: '90%',
                    }}
                    variant="body2Medium"
                >
                    {header}
                </Typography>
                <Image
                    className={`${classes.arrow} ${open ? classes.open : ''}`}
                    src={ArrowDown.src}
                    width={18}
                    height={18}
                    alt="expand accordion"
                />
            </button>
            <div
                className={`${classes.accordion_body} ${
                    open ? classes.open : ''
                }`}
                style={{ borderBottom: lastQuestion && open ? 'none' : '' }}
            >
                <div
                    style={{
                        fontFamily: 'inherit',
                        color: '#0c0c0c',
                        fontSize: '14px',
                        fontWeight: 400,
                        opacity: 0.8,
                    }}
                    className={classes.faqAnsweredQuestions}
                    dangerouslySetInnerHTML={{ __html: items }}
                />
            </div>
        </div>
    );
};

const FAQs = () => {
    const { t, i18n } = useTranslation();

    const [mainFaq, setMainFaq] = useState<any>([]);
    const [currentlyOpen, setCurrentlyOpen] = useState('');
    const lang = i18n.language;
    const { data, isLoading, isError, isFetching } = useGetFAQsQuery(lang);

    useEffect(() => {
        if (!isLoading && !isError) {
            setMainFaq(data.data[0].faqs);
        }
    }, [isLoading, isError, isFetching]);

    const openAccordion = (header: string) => {
        if (header === currentlyOpen) {
            setCurrentlyOpen('');
            return;
        }
        mainFaq.forEach((faq: any) => {
            if (faq.question === header && header !== currentlyOpen)
                setCurrentlyOpen(header);
        });
    };
    return (
        <div className={`${classes.faq}`}>
            <div className={classes.faqs_container}>
                <Typography variant="h6Bold">
                    {t('Popular Questions')}
                </Typography>

                <div className={classes.accordion_container}>
                    <div className={classes.all_faqs_container}>
                        {mainFaq.map((faq: any, i: number) => (
                            <Accordian
                                key={faq._id}
                                expandQuestion={openAccordion}
                                open={currentlyOpen === faq.question}
                                header={faq.question}
                                items={faq.answer}
                                lastQuestion={i === mainFaq.length - 1}
                            />
                        ))}
                    </div>
                </div>
                <Link
                    className={classes.whiteButton}
                    href={{
                        pathname: `/${lang}/how-it-works`,
                        // state: faqs
                    }}
                >
                    <Typography>{t('View All FAQs')}</Typography>
                </Link>
            </div>
        </div>
    );
};
export default FAQs;
