import { useState, FC, CSSProperties } from 'react';
import styles from './styles.module.scss';
import Image from 'next/image';
import { playButton } from 'public/Images';
import Chip from '@mui/material/Chip';
import RatingStars from '../rating-stars/rating-stars.component';
import { Typography } from '@mui/material';
import ReactPlayer from 'react-player';
import { useTranslation, useWindowDimensions } from '@/hook';
import { Dialog } from '@mui/material';
import myImageLoader from '@/image.loader';
import ConditionalClientComponent from '@/components/shared/conditional/conditional-client.component';

const VidOverlay: FC<{
    shortUrl: string;
    thumbnailUrl: string;
    longUrl: string;
    category: string;
    rating: number;
    userName: string;
    playing: boolean;
    setPlaying: (index: number) => void;
    index: number;
    gtmEvent: () => void;
}> = ({
    shortUrl,
    thumbnailUrl,
    longUrl,
    category,
    rating,
    userName,
    gtmEvent,
    playing,
    setPlaying,
    index,
}) => {
    const { t } = useTranslation();
    const [isFullScreen, setIsFullScreen] = useState(false);
    const { IS_DESKTOP } = useWindowDimensions();
    const dialogDesktopStyles = {
        width: '70vw',
        maxWidth: '70vw',
        height: '40vw',
        maxHeight: '40vw',
        margin: '0px',
        overFlow: 'hidden',
    };
    const vidPlayerDesktopStyles = {
        height: '100%',
        maxHeight: '100%',
        width: '100%',
        backgroundColor: 'black',
    };
    const dialogMobileStyles = {
        width: '100%',
        margin: '0px',
        height: 'fit-contnet',
    };
    const vidPlayerMobileStyles = {
        position: 'fixed',
        top: '25%',
        height: '50%',
        backgroundColor: 'black',
    };

    return (
        <div>
            <div
                className={styles.youtube_container}
                onClick={() => {
                    !isFullScreen ? setPlaying(index) : '';
                }}
            >
                <div
                    className={
                        playing ? styles.hidden : styles.rating_category_cont
                    }
                >
                    <div className={styles.rating_category_row}>
                        <div className={styles.play_button}>
                            <Image
                                src={playButton.src}
                                width={40}
                                height={40}
                                alt="play"
                            />
                            <Typography variant="caption">00:10</Typography>
                        </div>
                        <div className={styles.rating_info}>
                            <Chip
                                label={category}
                                className={styles.category_chip}
                            />
                            <div>
                                <Typography
                                    variant="body2"
                                    style={{ color: 'white' }}
                                >
                                    {userName}
                                </Typography>
                                <RatingStars rating={rating} />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={
                        playing ? styles.vid_pause_overlay : styles.hidden
                    }
                >
                    <div onClick={() => setIsFullScreen(true)}>
                        <Image
                            src={playButton.src}
                            width={20}
                            height={20}
                            alt="open full"
                        />
                        {t('playFulllReview')}
                    </div>
                </div>
                {
                    <ConditionalClientComponent condition={true}>
                        <ReactPlayer
                            width="100%"
                            height="100%"
                            url={shortUrl}
                            playsinline={true}
                            onEnded={() => setPlaying(index)}
                            light={
                                <div className={styles.thumbnail}>
                                    <img
                                        src={myImageLoader({
                                            src: thumbnailUrl,
                                            height: 300,
                                            width: 300,
                                            quality: 99,
                                        })}
                                        alt="thumbnail"
                                    ></img>
                                </div>
                            }
                            playIcon={
                                <span
                                    style={{
                                        position: 'absolute',
                                        width: '100%',
                                        height: '100%',
                                    }}
                                ></span>
                            }
                            playing={playing}
                            onPlay={() => {
                                gtmEvent();
                            }}
                        />
                    </ConditionalClientComponent>
                }
            </div>
            <Dialog
                open={isFullScreen}
                disablePortal={false}
                fullWidth={IS_DESKTOP ? false : true}
                onClose={() => {
                    setIsFullScreen(false);
                }}
                PaperProps={{
                    style: IS_DESKTOP
                        ? dialogDesktopStyles
                        : dialogMobileStyles,
                }}
            >
                <ReactPlayer
                    url={longUrl}
                    width={'100%'}
                    style={
                        IS_DESKTOP
                            ? (vidPlayerDesktopStyles as CSSProperties)
                            : (vidPlayerMobileStyles as CSSProperties)
                    }
                    height={IS_DESKTOP ? '100%' : '360px'}
                    controls={true}
                    playing={true}
                    playsinline={true}
                    config={{
                        file: {
                            attributes: {
                                controlsList: 'nodownload',
                                controls: true,
                            },
                        },
                    }}
                    onPlay={() => gtmEvent()}
                />
            </Dialog>
        </div>
    );
};

export default VidOverlay;
