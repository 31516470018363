import { COMMON_SERVER_HEADERS, RETAIL_BACKEND, ServiceName } from '@/constants';
import { Testimonial } from '@/types';
import { TIME_IN_SECONDS } from '@/constants/time';
import To from '@/libs/to-promise';
import pino from 'pino';

const logger = pino().child({ s: 'src/api-requests/testimonials.apis.ts' });

export async function getTestimonial(serviceName: ServiceName): Promise<Testimonial[]> {
    const [err, res] = await To(
        fetch(`${RETAIL_BACKEND}/testimonials/paginated/100/0/{"serviceName":"${serviceName}","status":"PUBLISHED"}/{"sortBy":"response_at","orderBy":"desc"}`, {
            headers: { ...COMMON_SERVER_HEADERS },
            next: {
                revalidate: TIME_IN_SECONDS.minute * 30,
                //IS_IGNORING_CACHE_SETTING.
            },
        })
    );
    if (err) {
        logger.info(err);
        return [] as Testimonial[];
    }
    const [dataParseError, data] = await To(res.json());
    if (dataParseError) {
        logger.info('Error while trying to parse getTestimonial response.');
        logger.info('original data provided is ', data);
        logger.error(dataParseError);
        return [] as Testimonial[];
    }
    return data?.data.testimonials || [];
}
