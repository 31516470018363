import { Make, Model } from '@/types/acquistion/atomic-sell-form.types';
import { Area, Region } from '@/types/acquistion/region.types';
import { formatRegionsForDisplay, sortDataBasedOnShoppingList } from './utils';
import { ACQUISITION_BACKEND } from '@/constants';
import { ICity } from '@/types/location.types';
import axios from 'axios';

export const fetchMakes = async () => {
    const data = await axios.get(`${ACQUISITION_BACKEND}/v3/shoppingList/make`);
    const parsedData = data.data.data.map((make: Make) => make);
    return sortDataBasedOnShoppingList<Make>(parsedData);
};

export const fetchModel = async (make: Make | undefined) => {
    const data = await axios.get(
        `${ACQUISITION_BACKEND}/v3/shoppingList/model/${make.id}`
    );
    const parsedData = data.data.data.map((model: Model) => ({
        ...model,
        inShoppingList:
            model.yearsKm.filter(year => year.inShoppingList).length > 0,
        image: make.image,
    }));
    return sortDataBasedOnShoppingList<Model>(parsedData);
};

export const fetchRegions = async () => {
    const data = await axios.get(`${ACQUISITION_BACKEND}/areas`);

    const parsedData = data.data.data.map((city: ICity) => ({
        id: city.cityId,
        bigArea: city.cityEnName,
        arBigArea: city.cityArName,
        smallArea: city.areas.map(
            area =>
                ({
                    id: area.areaId,
                    polygon: area.coordinates.map(coord => ({
                        lng: coord.longitude,
                        lat: coord.latitude,
                    })),
                    name: area.areaEnName,
                    arName: area.areaArName,
                    serviceable: area.serviceable,
                    warehouse: area.warehouse,
                } as Area)
        ),
    })) as Region[];

    return formatRegionsForDisplay(
        parsedData.filter(item => item.smallArea.length)
    );
};
