'use client';
import classes from './styles.module.scss';
import { useTranslation } from '@/hook';
import Slider from 'react-slick';
import { useLayoutEffect, useState } from 'react';
import { work3, work4 } from '../../../../public/Images/old-images';
import Typography from '@/components/shared/typography/typography.component';
import Image from 'next/image';

function HowItWorksSection() {
    const { t, i18n, IS_RTL } = useTranslation();
    const lang = i18n.language;
    const work2ImgUrl =
        'https://retail-backend-resources-dev.s3.us-east-2.amazonaws.com/vehicle-images/C-1420/Exterior/1683106276857-work2.png';
    const howWeWorkSlider = [
        <div className={classes.workItem} key={'1'}>
            <Image width={150} height={150} src={work2ImgUrl} alt="work2" />
            <Typography
                variant="subtitle1Bold"
                style={{ whiteSpace: 'nowrap' }}
            >
                {t('Get a Call')}
            </Typography>
            <Typography variant="body2" className={classes.workItemDesc}>
                {t('Customer Support')}
            </Typography>
        </div>,
        <div className={classes.workItem} key={'2'}>
            <Image
                width={150}
                height={150}
                src={work3.src}
                alt="work3"
                style={{ width: '150px' }}
            />
            <Typography
                variant="subtitle1Bold"
                style={{ whiteSpace: 'nowrap' }}
            >
                {t('Get your Offer')}{' '}
            </Typography>
            <Typography variant="body2" className={classes.workItemDesc}>
                {t('Work3')}
            </Typography>
        </div>,

        <div className={classes.workItem} key={'3'}>
            <Image
                width={150}
                height={150}
                src={work4}
                alt="work1"
                style={{ width: '150px' }}
            />
            <Typography style={{ textAlign: 'center' }} variant="subtitle1Bold">
                {t('Your Sale')}
            </Typography>
            <Typography variant="body2" className={classes.workItemDesc}>
                {t('Work4')}
            </Typography>
        </div>,
    ];
    const workSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        rtl: IS_RTL,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: 'work-slider',
        arrows: false,
        responsive: [
            {
                breakpoint: 768,
            },
            {
                breakpoint: 10000, // a unrealistically big number to cover up greatest screen resolution
                settings: 'unslick',
            },
        ],
    };

    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);

        useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth, window.innerHeight]);
            }

            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }

    function ShowWindowDimensions() {
        const [width] = useWindowSize();
        return width;
    }

    return (
        <div className={classes.work}>
            <div className={classes.HowItWorksContainer}>
                <Typography className={classes.title} variant={'h4Bold'}>
                    {t('How it works?')}
                </Typography>

                <div className={classes.desktopContainer}>
                    {ShowWindowDimensions() < 770 && lang === 'ar'
                        ? howWeWorkSlider.reverse().map(station => station)
                        : howWeWorkSlider.map(station => station)}
                </div>

                <div className={classes.mobileContainer}>
                    {/* @ts-ignore */}
                    <Slider {...workSettings}>
                        {/*// @ts-ignore*/}
                        {ShowWindowDimensions() < 770 && lang === 'ar'
                            ? howWeWorkSlider.reverse().map(station => station)
                            : howWeWorkSlider.map(station => station)}
                    </Slider>
                </div>
            </div>
        </div>
    );
}

export default HowItWorksSection;
