import { EInspectionType } from '@/types/acquistion/atomic-sell-form.types';
import { useStepperValues } from './utils';
import { FEATURE_FLAG_SHOW_SYLNDR_HUBS } from '@/constants';

export const useCheckers = () => {
    const { inspectionType, year, transmission, kilometers } =
        useStepperValues();

    const skipIfWarehouseInspectioin = () => {
        return inspectionType?.id === EInspectionType.WAREHOUSE;
    };

    const skipIfOutshoppingList = () => {
        if (year && transmission && kilometers)
            return !(
                year?.inShoppingList &&
                transmission?.inShoppingList &&
                kilometers?.inShoppingList
            );
        else false;
    };
    const skipIfHomeInspectioin = () => {
        if (!FEATURE_FLAG_SHOW_SYLNDR_HUBS) return true;

        return inspectionType?.id === EInspectionType.HOME;
    };
    return {
        skipIfWarehouseInspectioin,
        skipIfOutshoppingList,
        skipIfHomeInspectioin,
    };
};
