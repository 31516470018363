'use client';
import TestimonialVids from '@/components/testimonialVidSlider/testimonial-vids.component';
import { Box } from '@mui/material';
import { useTranslation } from '@/hook';
import styles from './styles.module.scss';
import { NewTestimonialSection } from '../acquisition';
import { getTestimonial } from '@/api-requests/testimonials.api';
import To from '@/libs/to-promise';
import { Testimonial } from '@/types';
import { useEffect, useState } from 'react';
import { ServiceName } from '@/constants';

const VidTestimonialsSection = ({ SourceScreen, serviceName }: { SourceScreen: string, serviceName: ServiceName }) => {
    const { t } = useTranslation();
    const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
    useEffect(() => {
        To<Testimonial[], Error>(getTestimonial(serviceName))
            .then(([err, data]) => {
                if (!err) {
                    setTestimonials(data);
                }
            })
            .catch(() => {
                //nothing.
            });
    }, []);
    return (
        <Box className={styles.testimonials}>
            <h2 className={'font-bold'}>{t('sellPage.whatTheySaid')}</h2>
            <TestimonialVids
                sourceScreen={SourceScreen}
                backgroundColor={'#f3fffa'}
            />
            <div className={styles.separator_line}></div>
            <NewTestimonialSection
                sourceScreen={SourceScreen}
                testimonials={testimonials}
            />
        </Box>
    );
};

export default VidTestimonialsSection;
