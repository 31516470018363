import {
    Region,
    smallArea,
    formatedRegion,
} from '@/types/acquistion/region.types';
import { useAtomValue } from 'jotai';
import {
    kilometerAtom,
    makeAtom,
    modelAtom,
    yearAtom,
    transmitionAtom,
    inspectionTypeAtom,
    contactInfoAtom,
    locationAtom,
    sylndrHubsAtom,
} from '@/atomic-store/sell-form.atoms';
import {
    IContactInfo,
    EInspectionType,
    genericStep,
} from '@/types/acquistion/atomic-sell-form.types';
import { ILeadRequest, IPerspectiveLeadRequest } from '@/types';
import { useTranslation } from '@/hook';

export const sortDataBasedOnShoppingList = <T extends genericStep>(
    displayedArray: T[]
) => {
    return displayedArray.sort((a, b) => {
        if (a.inShoppingList && !b.inShoppingList) {
            return -1;
        } else if (!a.inShoppingList && b.inShoppingList) {
            return 1;
        } else {
            return 0;
        }
    });
};

export const formatRegionsForDisplay = (
    regions: Region[]
): formatedRegion[] => {
    const formatedLocations: formatedRegion[] = regions?.map(bigArea => {
        const smallAreas: smallArea[] = bigArea?.smallArea?.map(smallArea => {
            const area: smallArea = {
                id: smallArea.id,
                nameEn: smallArea.name,
                nameAr: smallArea.arName,
                selected: false,
                showAlert: false,
                polygon: smallArea.polygon,
                warehouse: smallArea.warehouse,
                serviceable: smallArea.serviceable,
            };
            return area;
        });
        const location: formatedRegion = {
            id: bigArea.id,
            bigAreaEn: bigArea.bigArea,
            bigAreaAr: bigArea.arBigArea,
            expanded: false,
            smallArea: [...smallAreas],
        };
        return location;
    });
    return formatedLocations;
};

export const useStepperValues = () => {
    const { IS_RTL } = useTranslation();
    const inspectionType = useAtomValue(inspectionTypeAtom);
    const make = useAtomValue(makeAtom);
    const model = useAtomValue(modelAtom);
    const year = useAtomValue(yearAtom);
    const kilometers = useAtomValue(kilometerAtom);
    const transmission = useAtomValue(transmitionAtom);
    const region = useAtomValue(locationAtom);
    const contactInfo = useAtomValue(contactInfoAtom);
    const sylndrHub = useAtomValue(sylndrHubsAtom);
    const regionExists = inspectionType?.id === EInspectionType.HOME;

    const buildLeadObject = (
        otp: string,
        lang: string,
        userInfo: IContactInfo
    ) => {
        const leadObj: ILeadRequest = {
            year: year.id,
            name: userInfo.name,
            make: {
                id: make.id,
                enName: make.enName,
            },
            model: {
                id: model.id,
                enName: model.enName,
            },
            transmission: transmission.id !== '' ? transmission?.id : 'NA',
            trafficUnit: 'NA',
            region:
                regionExists &&
                (region?.smallArea !== '' || region?.addressCity !== '')
                    ? region?.smallArea
                    : 'NA',
            regionObject:
                regionExists &&
                (region?.smallArea !== '' || region?.addressCity !== '')
                    ? {
                          _id: region?.id,
                          bigArea: region?.bigArea,
                          smallArea: region?.smallArea,
                          name: region?.smallArea,
                          address: region?.address,
                          coordinate: region?.sendPinLocation
                              ? region?.coordinate
                              : null,
                          addressCity: region?.addressCity,
                          serviceable: region?.serviceable,
                          warehouse: region?.warehouse,
                      }
                    : null,
            phone: userInfo.phone,
            phoneNumber: userInfo.phone,
            otp: otp,
            email: userInfo?.email,
            kilometers: +kilometers.id,
            language: lang,
            specificContactTime: {},
            inspectionType: inspectionType.id,
            hubLocation:
                inspectionType.id === EInspectionType.WAREHOUSE && sylndrHub
                    ? {
                          hubLocation: sylndrHub.hub.hubGoogleLocation,
                          hubName: IS_RTL
                              ? sylndrHub.hub.titleAR
                              : sylndrHub.hub.titleEN,
                          id: sylndrHub.id,
                      }
                    : null,
        };

        if (userInfo.userId) leadObj.userId = userInfo.userId;

        return leadObj;
    };

    const buildPerspectiveLeadObject = (
        lang: string,
        userInfo: IContactInfo
    ) => {
        const perspectiveLeadObj: IPerspectiveLeadRequest = {
            year: year.id,
            name: userInfo.name,
            make: {
                id: make.id,
                enName: make.enName,
            },
            model: {
                id: model.id,
                enName: model.enName,
            },
            transmission: transmission.id !== '' ? transmission?.id : 'NA',
            trafficUnit: 'NA',
            regionObject:
                regionExists &&
                (region?.smallArea !== '' || region?.addressCity !== '')
                    ? {
                          _id: region?.id,
                          bigArea: region?.bigArea,
                          smallArea: region?.smallArea,
                          name: region?.smallArea,
                          address: region?.address,
                          coordinate: region?.sendPinLocation
                              ? region?.coordinate
                              : null,
                          addressCity: region?.addressCity,
                          serviceable: region?.serviceable,
                          warehouse: region?.warehouse,
                      }
                    : null,
            phone: userInfo.phone,
            phoneNumber: userInfo.phone,
            email: userInfo?.email,
            kilometers: +kilometers.id,
            language: lang,
            inspectionType: inspectionType.id,
            userId: userInfo.userId,
        };
        return perspectiveLeadObj;
    };

    return {
        buildLeadObject,
        buildPerspectiveLeadObject,
        inspectionType,
        make,
        model,
        year,
        kilometers,
        transmission,
        contactInfo,
        region,
        sylndrHub,
    };
};
