import { useEffect, useState } from 'react';

const Conditional = ({
    condition,
    children,
}: {
    condition: boolean;
    children: JSX.Element;
}) => {
    const [shouldMount, setShouldMount] = useState<boolean>(false);
    useEffect(() => {
        setShouldMount(!!condition);
    }, [condition]);

    return shouldMount && children;
};
export default Conditional;
