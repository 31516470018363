import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { NEXT_APP_SYLNDR_GOOGLE_MAP_API_KEY } from '@/constants';
import { ICoordinate } from '@/types';

interface GeocoderResult {
    address_components: google.maps.GeocoderAddressComponent[];
    formatted_address: string;
    geometry: google.maps.GeocoderGeometry;
    place_id: string;
    types: string[];
}

interface GeocodeQuery {
    latLng: ICoordinate;
    lang: string;
}

export const geocoderApiSlice = createApi({
    reducerPath: 'geocoderApi',
    baseQuery: fetchBaseQuery({ baseUrl: '' }),
    endpoints: builder => ({
        reverseGeocode: builder.query<GeocoderResult, GeocodeQuery>({
            query: (data: GeocodeQuery) => ({
                url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${data?.latLng.lat},${data?.latLng.lng}&language=${data?.lang}&key=${NEXT_APP_SYLNDR_GOOGLE_MAP_API_KEY}`,
                method: 'GET',
            }),
            transformResponse: (response: any) => response.results[0], // only return the first result
        }),
    }),
});

export const { useReverseGeocodeQuery, useLazyReverseGeocodeQuery } =
    geocoderApiSlice;
