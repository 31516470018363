import {
    AcquisitionDesktopHeader,
    FAQs,
    HowItWorksSection,
    TestimonialSection,
    WhySellYourCarSection,
} from '@/components/acquisition';

import styles from './styles.module.scss';

import VidTestimonialsSection from '@/components/testimonialVidSection/vid-testimonials.component';
import { Box } from '@mui/material';
import { FEATURE_FLAG_SHOW_SYLNDR_HUBS, ServiceName } from '@/constants';
import MobileSellCarPageFixedLocation from './mobile-sell-car-page/mobile-sell-car-fixed-location.component';

import dynamic from 'next/dynamic';

const MobileSellCarPageMultipeLocationDynamic = dynamic(
    () =>
        import(
            './mobile-sell-car-page/mobile-sell-car-multipe-locations.component'
        ).then(mod => mod.default),
    {
        ssr: false,
    }
);

const AcquisitionPage = () => {
    return (
        <div>
            <div className={styles.desktop_header}>
                <AcquisitionDesktopHeader />
                <HowItWorksSection />
                <TestimonialSection />
                <WhySellYourCarSection />
                <FAQs />
            </div>
            <div className={styles.banner_contianer}>
                {FEATURE_FLAG_SHOW_SYLNDR_HUBS ? (
                    <MobileSellCarPageMultipeLocationDynamic />
                ) : (
                    <MobileSellCarPageFixedLocation />
                )}

                <Box className={styles.testimonialContainer}>
                    <VidTestimonialsSection
                        SourceScreen="Sell Car"
                        serviceName={ServiceName.ACQUISITION}
                    />
                </Box>
                <FAQs />
            </div>
        </div>
    );
};

export default AcquisitionPage;
